import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ApplyModal from '../components/ApplyModal';
import CtaUploadCV from '../components/CtaUploadCV';
import JobCard from '../components/JobCard';

import serializers from '../utilities/serializers';
import { applyModalHandler } from '../utilities/helpers';
import './job-template.scss';

import IconLocation from '../icons/IconLocation';
import IconTimer from '../icons/IconTimer';
import IconMoney from '../icons/IconMoney';

export default function JobDetails({ data }) {
  const {
    title,
    assignedTo,
    workplace,
    jobType,
    datePosted,
    dateExpiry,
    expiry,
    filled,
    category,
    sub,
    location,
    salary,
    benefits,
    short,
    _rawJobContent,
  } = data.sanityJobs;
  const { siteUrl } = data.site.siteMetadata;
  const allJobsRaw = data.allSanityJobs.nodes;
  const today = new Date().toISOString().slice(0, 10);
  const jobExpired = dateExpiry < today;

  // Don't list jobs that are to be posted later or expired
  const allJobsTillToday = allJobsRaw.filter(
    (job) => job.datePosted <= today && job.dateExpiry >= today
  );
  const allCategoryJobs = allJobsTillToday.filter(
    (jobs) => jobs.category.categoryName === category.categoryName
  );
  const allCatJobsWLimit = allCategoryJobs.slice(0, 3);

  // Check if window is undefined
  const isBrowser = typeof window !== 'undefined';
  function handleCatSelection() {
    if (isBrowser) {
      window.currentState.category = category.categoryName;
      window.currentState.location = 'all';
    }
  }

  useEffect(() => {
    applyModalHandler();
  });

  return (
    <>
      {!jobExpired && !filled && (
        <ApplyModal
          title={title}
          expanded={category.additional}
          applicationEmail={assignedTo.socials.email}
        />
      )}

      <SEO
        title={`Job – ${title}`}
        description={short}
        image={`${siteUrl}/jobs-preview.png`}
      />

      <Layout>
        <section className="job-hero">
          <div className="wrapper centered">
            <div className="heading-content">
              <div className="tags">
                <span className="job-tag big remote">
                  {workplace === 'onsite' && 'On-site'}
                  {workplace === 'hybrid' && 'Hybrid'}
                  {workplace === 'remote' && 'Remote'}
                </span>
                {jobType === 'fulltime' ? (
                  <span className="job-tag big fulltime">Full time</span>
                ) : (
                  <span className="job-tag big contract">Contract</span>
                )}
              </div>
              <h1>{title}</h1>
              <p className="mono post-info">
                Posted on {datePosted} in{' '}
                <Link
                  to="/jobs"
                  className="link-dashed"
                  onClick={() => handleCatSelection()}
                >
                  {category.categoryName}
                </Link>{' '}
                ({sub.subcat})
              </p>
              <div className="post-info-2">
                <div className="info location">
                  <IconLocation />
                  <p>{location.loc}</p>
                </div>
                <div className="info date">
                  <IconTimer />
                  <p>
                    {
                    /* eslint-disable */
                    jobExpired || filled
                      ? filled
                        ? 'Job filled'
                        : 'Job expired'
                      : `${expiry.slice(3)} left`}
                  </p>
                </div>
                {salary && (
                  <div className="info salary">
                    <IconMoney />
                    <p>{salary}</p>
                </div>
                )}
              </div>
              
              {benefits.length > 0 && (
                <div className="all-benefits">
                  {benefits.map((benefit, i) => (
                    <p className="benefit" key={i}>{benefit}</p>
                  ))}
                </div>
              )}

              <button
                className="open-job-form open-apply-modal"
                type="button"
                disabled={jobExpired || filled}
              >
                {jobExpired || filled
                  ? filled
                    ? 'Job filled'
                    : 'Job expired'
                  : 'Apply for job'
                  /* eslint-enable */
                }
              </button>
            </div>
          </div>
        </section>

        <section className="job-template">
          <div className="wrapper centered">
            <div className="template-content">
              {short && <p className="summary">{short}</p>}

              <PortableText blocks={_rawJobContent} serializers={serializers} />

              <div className="template-footer">
                <div className="assigned-member">
                  <div className="img">
                    <GatsbyImage
                      image={assignedTo.pic.asset.gatsbyImageData}
                      alt={assignedTo.name}
                    />
                  </div>
                  <div className="info">
                    <h3>{assignedTo.name}</h3>
                    <p className="mono occupation">{assignedTo.designation}</p>
                  </div>
                </div>
                {!jobExpired && !filled && (
                  <div className="cta">
                    <button
                      className="open-job-form open-apply-modal"
                      type="button"
                    >
                      Apply for job
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {allCatJobsWLimit.length > 0 && (
          <section className="more-jobs">
            <div className="wrapper centered">
              <div className="heading">
                <h2>More {category.categoryName} jobs</h2>
              </div>

              <div className="all-jobs">
                {allCatJobsWLimit.map((job, i) => (
                  <JobCard job={job} key={i} />
                ))}
              </div>

              <div className="cta">
                <Link
                  to="/jobs"
                  className="link-dashed"
                  onClick={() => handleCatSelection()}
                >
                  View all {category.categoryName} jobs
                </Link>
              </div>
            </div>
          </section>
        )}

        <CtaUploadCV />
      </Layout>
    </>
  );
}

export const query = graphql`
  query GetSingleJob($slug: String) {
    sanityJobs(slug: { current: { eq: $slug } }) {
      title
      assignedTo {
        name
        designation
        socials {
          email
        }
        pic {
          asset {
            gatsbyImageData
          }
        }
      }
      location {
        loc
      }
      datePosted(formatString: "MMM D, YYYY")
      dateExpiry
      filled
      expiry: dateExpiry(fromNow: true)
      category {
        categoryName
        additional
      }
      sub {
        subcat
      }
      workplace
      jobType
      salary
      benefits
      short
      _rawJobContent
    }
    allSanityJobs(
      sort: { fields: datePosted, order: DESC }
      # filter: { filled: { ne: true } }
      filter: { slug: { current: { ne: $slug } }, filled: { ne: true } }
    ) {
      nodes {
        slug {
          current
        }
        title
        location {
          loc
        }
        date: datePosted(formatString: "MMM D, YYYY")
        datePosted
        dateExpiry
        category {
          categoryName
        }
        sub {
          subcat
        }
        workplace
        jobType
        salary
      }
    }
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteUrl
      }
    }
  }
`;
