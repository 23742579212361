/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import IconClose from '../icons/IconClose';

export default function ApplyModal({ title, expanded, applicationEmail }) {
  const fileChangedHandler = (event) => {
    const fileSize = event.target.files[0].size / (1024 * 1024);
    // const fileName = event.target.files[0].name;
    // const fileType = event.target.files[0].type;
    const filePath = event.target.value;
    if (fileSize > 25) {
      alert('FIle size should be smaller than 25MB');
      event.target.value = '';
    }

    const allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Invalid file type');
      event.target.value = '';
    }
  };

  const [userEmail, setUserEmail] = useState('');
  const [state, handleSubmit] = useForm('xwkyyjyv', {
    data: {
      subject: `Application for ${title} from ${userEmail}`,
    },
  });

  return (
    <>
      <div
        className={`modal ${
          state.succeeded ? 'success-modal show' : 'apply-modal'
        }`}
      >
        <div className="modal-content has-submitting-state">
          {state.succeeded ? (
            <div className="success-content">
              <span>🎉</span>
              <h2>Form Submitted!</h2>
              <p>We've received your application, thank you!</p>
            </div>
          ) : (
            <form method="POST" onSubmit={handleSubmit}>
              <h2>Apply for {title}</h2>

              <input
                type="text"
                name="Applying for:"
                value={title}
                className="hidden"
              />

              <div className="two-col-group">
                <div className="form-group">
                  <label htmlFor="name">Full Name*</label>
                  <input id="name" type="text" name="Name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email*</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Message (optional)</label>
                <textarea
                  name="Message"
                  placeholder="Cover letter or message to the employer"
                />
              </div>

              <div className="two-col-group">
                <div className="form-group">
                  <label htmlFor="uploadcv">Upload CV*</label>
                  <input
                    id="uploadcv"
                    type="file"
                    name="CV"
                    accept=".pdf,.doc,.docx"
                    required
                    onChange={fileChangedHandler}
                  />
                  <span className="info">
                    CV/resume (pdf/word file &#60;25MB)
                  </span>
                </div>
                {expanded && (
                  <div className="form-group">
                    <label htmlFor="additionalfile">Additional file</label>
                    <input
                      id="additionalfile"
                      type="file"
                      name="Additional file"
                      accept=".pdf,.doc,.docx"
                      onChange={fileChangedHandler}
                      placeholder="Portfolio"
                    />
                    <span className="info">
                      Eg. portfolio (pdf/word file &#60;25MB)
                    </span>
                  </div>
                )}
              </div>

              <div className="radio-group curious">
                <input
                  type="checkbox"
                  name="Casually looking"
                  id="casually"
                  className="light-border"
                  defaultChecked={false}
                />
                <label htmlFor="casually">
                  I’m casually looking/curious about what’s out there
                </label>
              </div>

              <input
                type="text"
                name="targetEmail"
                value={applicationEmail}
                className="hidden"
              />

              <div className="cta">
                <button type="submit" disabled={state.submitting}>
                  Submit form
                </button>
              </div>
            </form>
          )}

          <div className="close">
            <IconClose />
          </div>

          <div className={`submitting ${state.submitting && 'show'}`}>
            <svg className="dashed-circle" viewBox="0 0 100 100">
              <circle
                cx="50"
                cy="50"
                r="48"
                strokeDasharray="10, 10"
                fill="transparent"
                stroke="#f24e4e"
                strokeWidth="2"
              />
            </svg>
            <p>
              Uploading file &amp;
              <br />
              Submitting
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
